

export const Theme: ThemeColors = {
    Sales: {hex: '#41BCFE', rgb: '65, 188, 254', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: 'Sales' },
    Tenant: {hex: '#6C5DD3', rgb: '108, 93, 211', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: 'Tenant'},
    Build: {hex: '#32CB84', rgb: '50, 203, 132', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: 'Build'},
    Management: {hex: '#FF8449', rgb: '255, 132, 73', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: 'Management'}
}

export const RichTextTheme : textFieldTheme = {
    Sales: {bg:'#F0FAFF', active: "grey", color:"#4CC4FE"},
    Tenant: {bg:'#F3F2FC', active:"grey", color:"#6C5DD3"},
    Build: {bg:'#F1FBF6', active:"grey", color:"#77c9a3"},
}

export type Color = {
    hex: string,
    rgb: string,
    secondary: {hex: string, rgb: string},
    role: string
}

export type ThemeColors = {
    Sales: Color,
    Tenant: Color,
    Build: Color,
    Management: Color
}

export type textFieldColor = {
    bg : string,
    active : string,
    color : string
}

export type textFieldTheme = {
    Sales: textFieldColor,
    Tenant: textFieldColor,
    Build: textFieldColor,
}