import { createSlice } from "@reduxjs/toolkit";
import { FormSlice } from "./types"

const form: any = {
    dropdown: {}

}
const initialState: FormSlice = {
    form: form,
};

const formReducer = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setDropDown: (state, action) => {
            state.form = { ...state.form, dropdown: action.payload }
        }
    },
    extraReducers: {}

})

export const { setDropDown } = formReducer.actions;

export default formReducer.reducer;