import React from 'react';
import styled from 'styled-components'
import './Button.scss'
import {useSelector } from "react-redux";
import { selectTheme } from "../../../redux/theme/theme.selector";

    const isCircleAndSmall = (props:any) => {
        return props.type === "circle" && props.size === "small";

    }

    const height = (props:any) => {
        if(isCircleAndSmall(props)){
            // return "auto";
            return "32px";
        } else if(props.type === "circle"){
            return "auto";
            // return "48px";
        } else if(props.type === "sm"){
            return "auto";
        }
        return "auto";
    }

    const width = (props:any) => {
        if(isCircleAndSmall(props)){
            return "32px";
        } else if(props.type === "circle"){
            return "48px";
        }
        return "auto";
    }

    const padding = (props:any) => {
        if(isCircleAndSmall(props)){
            return "0";
        } else if(props.type === "circle"){
            return "14px 15px";
        } else if(props.type === "sm"){
            return "10.5px 28px";
        } else if(props.pad === '15px'){
            return '14px 13px'
        }
        return "14px 28px";
    }

    const borderRadius = (props:any) => {
        if(props.type === "circle"){
            return "50%";
        } else if( props.type === "rounded") {
            return "6px";
        } else if(props.type === "sm"){
            return "30px";
        } else {
            return "30px"
        }
    }

    const fontSettings = (props:any) => {
        if( props.type === "rounded") {
            return {
                weight: "500",
                size: "14px",
                height: "15px",
            }
        } else if(props.type === "sm"){
            return {
                weight: "600",
                size: "13px",
                height: "18px",
            }
        } else {
            return {
                weight: "500",
                size: "14px",
                height: "132.2%",
            }
        }
    }

    const bgColor = (props:any, color:any) => {
        if(props.type === "rounded-sm" || props.theme ==="secondary"){
            return color.secondary.hex;
        }else if(props.type === "outlined"){
            return "transparent";
        }else {
            return color.hex;
        }
    }

    const border = (props:any, color:any) => {
        if(props.type === "outlined" && !props.borderColor){
            return "1px solid " + color.hex;
        } else if(props.borderColor){
            return "1px solid " + props.borderColor;
        } else {
            return "none";
        }
    }

    const fontColor = (props:any) => {
        if(props.type === "outlined" && !props.borderColor){
            return props.theme.hex;
        }
        return "#fff";
    }

    // ${props => props.circle ? "50%" : "30px"}
    const StyledButton = styled.button<any>`
        border-radius: ${props => borderRadius(props)};
        background: ${props => props.disabled ? "#cccccc" : props.bg};
        border: ${props => props.border};
        height: ${ props => height(props)};
        width: ${ props => width(props)};
        color: ${ props => props.color ? props.color : fontColor(props) };
        padding: ${props => padding(props)};    
        font-style: normal;
        font-weight: ${ props => fontSettings(props).weight };
        font-size: ${ props => fontSettings(props).size };
        line-height: ${ props => fontSettings(props).height };
        opacity : ${ props => props.disabled && 0.8 };
        
    `;

    const Icon = styled.span<any>`
        margin-left: ${props => props.type === "circle" ? "0" : "15px"};
        vertical-align:  ${props => props.type === "circle" ? "text-bottom" : "initial"};
    `;
const BIcon = styled.span<any>`
        margin-right: ${props => props.type === "circle" ? "0" : "15px"};
        vertical-align:  ${props => props.type === "circle" ? "text-bottom" : "initial"};
    `;

    function Button(props:any) {
        const theme = useSelector(selectTheme);
        return <>
                  <StyledButton onClick={props.onClick} {...props} theme={props.toggleTheme ? props.toggleTheme : theme} border={border(props, theme)} bg={props.bg ? props.bg : bgColor(props, props.toggleTheme ? props.toggleTheme : theme)}>
                      {props.iconBefore ? <>
                              {props.icon ? <BIcon type={props.type}>{props.icon}</BIcon> : null}
                              <span>{props.children}</span>
                          </> : <>
                              <span>{ props.children }</span>
                              { props.icon ? <Icon type={props.type}>{ props.icon }</Icon> : null }
                          </>
                      }
                  </StyledButton>
        </>

    }



  export default Button;