const GOOGLE_MAPS_API_KEY = 'AIzaSyBv708hJ6nM45qwROVJmWS_G3RxxL9GzTQ';

const loadGoogleMapScript = (callback?:any) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback && callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
}

export {loadGoogleMapScript}