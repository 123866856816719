import React, {useState} from "react";
import styled from "styled-components";
import WelcomeButton from "../../components/shared/WelcomeButton/WelcomeButton";
import ButtonPR from '../../components/shared/Button/Buttons';
import './WelcomePage.scss'
import { useHistory } from "react-router-dom";
import * as Actions from '../../redux'
import { useDispatch } from "react-redux";
import Button from "../../components/shared/Button/Buttons";
import LoginPage from "../loginPage/LoginPage";
import {LoginRoutes} from "../../platform/enums/Routes";
import {RoleEnum} from "../../platform/enums/role";

export const Theme: ThemeColors = {
    Sales: { hex: '#41BCFE', rgb: '65, 188, 254', secondary: { hex: "#32CB84", rgb: "127, 186, 122" }, role: 'Sales' },
    Tenant: { hex: '#6C5DD3', rgb: '108, 93, 211', secondary: { hex: "#32CB84", rgb: "127, 186, 122" }, role: 'Tenant' },
    Build: { hex: '#32CB84', rgb: '50, 203, 132', secondary: { hex: "#32CB84", rgb: "127, 186, 122" }, role: 'Build' },
    Management: { hex: '#FF8449', rgb: '255, 132, 73', secondary: { hex: "#32CB84", rgb: "127, 186, 122" }, role: 'Management' }
}
export type ThemeColors = {
    Sales: any,
    Tenant: any,
    Build: any,
    Management: any
}
const BGCircle = styled.div`
    position: fixed;
    width: 1564px;
    height: 1264px;
    z-index: -11;
    background: linear-gradient(180deg,#41BCFE 0%,#8FD8FE 100%);
    border-radius: 1023.84px;
    left: calc(50% - 1964px/2 - 327px);
    top: calc(50% - 1264px/2);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    animation-name: backgroundColorPalette;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
    animation-timing-function: linear; 
    // transition: all ease-in-out 0.1s;

    &::after{
        content: '';
        width: 250px;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 55%;
        background-repeat: no-repeat;
        background-size: contain;
        /* background-image: url('/static/icons/av-sales-login.svg'); */
        animation-name: backgroundImages;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        @media only screen and (max-width: 768px){
            display: none !important;
        }
        /* transition: all ease-in-out 0.1s; */
    }
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 52.8378px;
    line-height: 105%;
    margin-top: 115px;  
    /* or 55px */
    max-width: 634.05px;
    letter-spacing: -1.32095px;
    margin-bottom: 40px;
    /* White */

    color: #FFFFFF;

    `;

const PageDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 22.4561px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    color: #FFFFFF;
    max-width: 446.48px;
`;

const Container = styled.div`
    display: block;
    width:100vw;
`;

const Logo = styled.img`
    width:114px;
    position: relative;
    top: 75px;
    left: 81px;
`;

const HelpButton = styled(ButtonPR)`
    position: absolute;
    top: 40px;
    right: 10px;
`;

const openLink = (url: string) => {
    window.open(url, '_blank');
}
const WelcomePage: React.FC = () => {
    const [showLogin, setShowLogin] : any = useState(false);
    const [role, setRole]: any = useState("")

    const history = useHistory();
    const dispatch = useDispatch();

    const handleThemeClick = (name: string, selection: any) => {
        dispatch(Actions.setTheme(selection));
        setRole(name);
        setShowLogin(true);
    }
    const back = (value: string) => {
        setShowLogin(value);
    }
    return (
        <>
            {!showLogin ?
                <div className="welcome-wrapper-new">
                    <Container>
                        <Logo src="/static/icons/logo-new.svg" className="welcomelogo" />
                        <BGCircle />
                        <HelpButton onClick={() => openLink("https://www.notion.so/SDA-SHA-USAGE-DOCUMENTATION-272b3577317744c196df5ad6d3d41049")} className="quesbutton" type={"circle"} size="small" icon={<svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.07247 11.0312C3.57768 11.0312 3.98723 10.6216 3.98723 10.1164C3.98723 9.61121 3.57768 9.20166 3.07247 9.20166C2.56727 9.20166 2.15771 9.61121 2.15771 10.1164C2.15771 10.6216 2.56727 11.0312 3.07247 11.0312Z" fill="#fff" />
                            <path d="M3.07273 0.96875C1.45866 0.96875 0.145508 2.2819 0.145508 3.89597C0.145508 4.30015 0.473137 4.62777 0.877313 4.62777C1.28149 4.62777 1.60912 4.30015 1.60912 3.89597C1.60912 3.08894 2.26569 2.43236 3.07273 2.43236C3.87976 2.43236 4.53634 3.08894 4.53634 3.89597C4.53634 4.703 3.87976 5.35958 3.07273 5.35958C2.66855 5.35958 2.34092 5.68721 2.34092 6.09138V7.9209C2.34092 8.32507 2.66855 8.6527 3.07273 8.6527C3.4769 8.6527 3.80453 8.32507 3.80453 7.9209V6.73058C5.06565 6.40482 5.99995 5.25746 5.99995 3.89597C5.99995 2.2819 4.6868 0.96875 3.07273 0.96875Z" fill="white" />
                        </svg>}/>
                        <div className="row content-wrapper">
                            <div className="col-md-6">
                                <div className="page-info-wrapper">
                                    <Title>Please select a Department.</Title>
                                    <PageDescription>Pick your role in the company to be able to access the log in screen.</PageDescription>
                                    <PageDescription>Don’t have an account yet?</PageDescription>
                                    <PageDescription>Contact Administrator</PageDescription>
                                    <Button onClick={() => history.push(LoginRoutes.signup)}>Create Account</Button>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 badges-wrapper">
                                <WelcomeButton key={1} buttonClick={() => handleThemeClick('Sales', Theme.Sales)} bg={Theme.Sales.hex} bgsec="#0587F6" title={RoleEnum.Sales} classes="salesbutton mainbuttons" imageSrc='/static/icons/login-sales.svg'/>
                                <WelcomeButton key={2} buttonClick={() => handleThemeClick('Build', Theme.Build)} bg={Theme.Build.hex} bgsec={Theme.Build.hex} title={RoleEnum.Build} classes="buildbutton mainbuttons" imageSrc="/static/icons/login-build.svg"/>
                                <WelcomeButton key={3} buttonClick={() => handleThemeClick('Tenant', Theme.Tenant)} bg={Theme.Tenant.hex} bgsec={Theme.Tenant.hex} title={RoleEnum.Tenant} classes="tenantbutton mainbuttons" imageSrc="/static/icons/login-tenant.svg"/>
                                <WelcomeButton key={4} buttonClick={() => handleThemeClick('Manager', Theme.Management)} bg={Theme.Management.hex} bgsec={Theme.Management.hex} title="Manager" classes="investorbutton mainbuttons" imageSrc="/static/icons/login-investor.svg"/>
                            </div>
                        </div>
                    </Container>
                </div>
                :
                // @ts-ignore
                <LoginPage back={() => back()} role={role} />
            }
        </>
    );
}

export default WelcomePage;
