import { createSlice } from "@reduxjs/toolkit";
import { Theme } from "../../platform/enums/theme";
import { ThemeSlice } from "./types";

const initialState: ThemeSlice = {
    theme: Theme.Sales
};

const themeReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload
        }
    }
})

export const { setTheme } = themeReducer.actions;

export default themeReducer.reducer;