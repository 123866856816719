const jsonParse = (element:any, array:boolean = true) => {
    if(element == null){
        return array ? [] : {};
    }
    try {
        return JSON.parse(element);
    } catch (error) {
        return array ? [] : {};
    }
}

const openLink = (url:string) => {
    window.open(url, '_blank');
}

export {jsonParse, openLink}