import axios from "axios";
import clAPI from "./corelogic";

// const baseURL = 'https://sdashbe.displayau.com/api';
// const baseURL = 'http://192.168.10.7:8082/api';
const baseURL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: baseURL,
  headers: { Authorization: "Bearer " + sessionStorage.getItem("authToken") },
});

export { clAPI, baseURL };
