

export enum PublicRoutes {
    live = "/live",
    stocks = "/stocks",
    networkerror = "/network-error",
    home = "/",
}

export enum LoginRoutes {
    defaultPage = "/home",
    signup = "/auth/signup",
    resetpassword = "/auth/reset-password",
    changepassword = "/change-password",

}

export enum SaleRoutes {
    home = "/home",
    leads = "/leads",
    performance = "/performance",
    filemanager = "/file-manager",
    stocklist = "/stock-list",
    paidEois='/paid-eois'
}

export enum ReceptionistRoutes {
    home = "/home",
    leads = "/leads",
    filemanager = "/file-manager",
    design = "/design"

}

export enum TenantRoutes {
    home = "/home",
    allHouses = '/all-houses',
    buildHouses = '/active-builds',
    houselist = "/house-List",
    enquiries = "/enquiries",
    filemanager = "/file-manager",
    tickets = "/tickets",
    addlead = "/add-lead",
    nearbyhouse = "/near-by-house",
    houseinfo = "/house-info/:id",
    allEvents = "/all-events",
    allTenants = "/all-tenants"
}

export enum BuildRoutes {
    home = "/home",
    leads = "/leads",
    allHouses = '/all-houses',
    builds = "/builds",
    enquiries = "/enquiries",
    filemanager = "/file-manager",
    buildinfo = "/build-info/:id"

}

export enum ManagementRoutes {
    home = "/home",
    softwareusers = "/software-users",
    performance = "/performance",
    newusers = "/new-users",
    filemanager = "/file-manager"
}
