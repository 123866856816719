import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserSlice} from "./types"
import {LoginInterface, userObject} from "../../interfaces";
import {authService} from '../../services/auth.service'


export const loginUser = createAsyncThunk(
    'user/sign-up',
    async (data: LoginInterface) => {
        try {
            const res = await authService.loginUser(data)
            return res
        } catch (error) {
            console.log("error")
        }
    }
)

const user : userObject = {
    email: '',
    name: '',
    role: '',
    token: '',
    image: '',

}
const initialState: UserSlice = {
    user: user,
};

const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        }
    },
    extraReducers: {
        [loginUser.fulfilled.toString()]: (state, action) => {
            // localStorage.setItem('authToken', res.data.token);
            // localStorage.setItem('user', JSON.stringify(resUser));
        },
        [loginUser.rejected.toString()]: (state) => {

        }
    }

})

export const {setUser} = userReducer.actions;

export default userReducer.reducer;