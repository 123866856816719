import {createSlice} from "@reduxjs/toolkit";

const filterSelections : any = {
    name: '',
    stage: null,
    housePlan: null,
    builder: null,
    brf: null,
    scrollHeight: 0
}
const initialState: any = {
    filterSelections: filterSelections,
};

const filterSelectionsReducer = createSlice({
    name: 'filterSelections',
    initialState,
    reducers: {
        setFilterSelections: (state, action) => {
            state.filterSelections = action.payload
        },
        setScrollHeight: (state, action) => {
            state.filterSelections = {...state.filterSelections , scrollHeight : action.payload}
        },
        setInitialState : (state) => {
            state.filterSelections = filterSelections
        }
    },
})

export const {setFilterSelections, setScrollHeight, setInitialState} = filterSelectionsReducer.actions;

export default filterSelectionsReducer.reducer;
