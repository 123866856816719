


export const super_admin = "super_admin";
export const sales_manager = "sales_manager";
export const sales_man = "sales_man";
export const acquisition_manager = "acquisition_manager";
export const acquisition_assistant = "acquisition_assistant";
export const receptionist = "receptionist";

export const admin = "admin";
export const user = "user";

export enum RoleEnum {
    Sales = "Sales",
    Build = "Build",
    Tenant = "Tenant",
    Management = "Management",
  }

export enum RoleImages {
    Sales = "/static/icons/av-sales-login-blue.svg",
    Build = "/static/icons/av-sales-login-green.svg",
    Tenant = "/static/icons/av-sales-login-purple.svg",
    Management = "/static/icons/Admin.svg",
}

export enum SaleAccessLevels {
    super_admin = "super_admin",
    sales_manager = "sales_manager",
    sales_man = "sales_man",
    acquisition_manager = "acquisition_manager",
    acquisition_assistant = "acquisition_assistant",
    receptionist = "receptionist"
}

export enum TenantAccessLevels {
    super_admin = "super_admin",
    admin = "admin",
    user= "user"
}

export enum ManagementAccessLevels {
    super_admin ="super_admin",
    admin = "admin",
    user= "user"
}

export enum BuildAccessLevels {
    super_admin ="super_admin",
    admin = "admin",
    user= "user",
    accountant="accountant"
}

export const SuperManSections = {
    Sales : RoleEnum.Sales,
    Build : RoleEnum.Build,
    Tenant : RoleEnum.Tenant,
    Management : RoleEnum.Management,
    Receptionist : "Receptionist"
}

export const SupermanAccessForSections =  {
    Sales : SaleAccessLevels.super_admin,
    Receptionist : SaleAccessLevels.receptionist,
    Build : BuildAccessLevels.super_admin,
    Tenant : TenantAccessLevels.super_admin,
    Management : ManagementAccessLevels.super_admin,
}